@import "fonts";
@import "reset";
@import "vars";

:root {
    --primary-color: #34898b;
    --primary-color-var: #2a6365;

    --secondary-color: #2162a0;

    --background-color: #FFFFFF;
    --background-color-variant: #dddddd;
    --on-background-color: #000000;

    --color-danger: #ff0000;
    --color-warning: #ff8800;
    --color-success: #2d3e9b;

    --color-alt-success: rgb(9, 146, 77);
}

.flex {
    display: flex;
    flex-direction: row;

    &.column {
        flex-direction: column;
    }

    &.space-between {
        justify-content: space-between;
    }

    &.justify-center {
        justify-content: center;
    }

    &.align-center {
        align-items: center;
    }

    &.align-end {
        align-items: end;
    }

    &.gap5 {
        gap: 5px;
    }

    &.gap10 {
        gap: 10px;
    }

    &.gap15 {
        gap: 15px;
    }

    &.gap20 {
        gap: 20px;
    }

    &.gap25 {
        gap: 25px;
    }

    &.gap30 {
        gap: 30px;
    }
}

.vert-padding5 {
    padding-top: 5px;
    padding-bottom: 5px;
}

.vert-padding10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

.h-padding5 {
    padding-left: 5px;
    padding-right: 5px;
}

.h-padding10 {
    padding-left: 10px;
    padding-right: 10px;
}

.padding-bottom20 {
    padding-bottom: 20px;
}

.padding-bottom30 {
    padding-bottom: 30px;
}

.left-padding10 {
    padding-left: 10px;
}

.left-padding20 {
    padding-left: 20px;
}

.border-r4 {
    border-radius: 5px;
}

.border-r5 {
    border-radius: 5px;
}

.border-r10 {
    border-radius: 10px;
}

.grid-center {
    display: grid;
    place-items: center;
}

.b-shadow-small {
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
}

.b-shadow-medium {
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
}

.b-shadow-large {
    box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.3);
}

.error {
    color: var(--error-color);
    white-space: pre-line;
}

.not-allowed {
    cursor: not-allowed;
}

a.link {
    color: var(--primary-light-color);
}

body {
    min-height: 100dvh;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    color: var(--on-background-color);
    position: relative;
}

em {
    font-style: italic;

    * {
        font-size: inherit !important;
    }
}

strong {
    font-weight: bold;

    * {
        font-weight: inherit !important;
    }
}

.underline {
    text-decoration: underline;

    * {
        text-decoration: inherit !important;
    }
}

a,
div,
li,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
button,
input,
select,
textarea,
label {
    font-family: "nunito", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1em;
    color: inherit;

    @media (max-width: $small-size) {
        font-size: 0.95em;
    }
}

h1,
h2,
h3 {
    font-weight: bold;
}

h1 {
    font-size: 1.8em;
}

h2 {
    font-size: 1.5em;
}

h3 {
    font-size: 1.2em;
}

@media (max-width: $small-size) {
    h1 {
        font-size: 1.6em;
    }

    h2 {
        font-size: 1.3em;
    }

    h3 {
        font-size: 1em;
    }

    input {
        font-size: 1.1em;
    }
}

hr {
    border: none;

    &.h {
        border-top: 1px solid var(--background-color-variant);
        width: 100%;
    }

    &.v {
        border-left: 1px solid var(--background-color-variant);
        height: 100%;
    }

    @media (max-width: $mobile-size) {
        &.v.h-if-small {
            border-top: 1px solid var(--background-color-variant);
            border-left: none;
            width: 100%;
            height: auto;
        }
    }
}

@media (max-width: $tablet-size) {
    .display-only-desktop {
        display: none !important;
    }
}

@media (min-width: $tablet-size) {
    .display-only-mobile {
        display: none !important;
    }
}