* {
    padding: 0;
    margin: 0;
    outline: none;
    line-height: 1em;
    color: inherit;
    border: 0;
    box-sizing: border-box;
    font: inherit;
}
