.custom-c-modal-bg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.4);
    animation: fade-in 0.2s ease-in-out;

    .custom-c-modal {
        background-color: var(--background-color);
        color: var(--on-background-color);
        padding: 20px;
        max-width: 70vw;
        // max-height: 80dvh;

        .action-btn {
            align-self: end;
            align-items: center;
        }
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
}