button.var {
    padding: 1px 4px;
    border-radius: 3px;
    margin: 0 2px;
}

.examples {
    ul {
        li {
            margin-left: 20px;
        }
    }
}

.custom-c-modal {
    p {
        margin: 0;
        padding: 0;

        b {
            color: #888;
        }
    }

    p + div {
        margin-top: 5px;
    }

    input {
        border: 1px solid #ccc;
        width: 100%;
        padding: 10px;
    }
}