.grid-contract-parent {
    background-color: #ffc0f0;

    > button {
        align-self: end;
        margin-right: 20px;
        margin-bottom: 10px;
    }
}

.btn {
    margin: 0;
    padding: 5px;
    cursor: pointer;

    &.cancel {
        background-color: rgb(163, 19, 19);
        color: #fff;
        opacity: 0.9;

        &:hover {
            opacity: 1;
        }
    }

    &.confirm {
        background-color: #3c833c;
        color: #fff;
        opacity: 0.9;

        &:hover {
            opacity: 1;
        }

        &:disabled {
            background-color: #ccc;
        }
    }
}

#main-md-editor {
    z-index: 100;
    margin: 40px 0;
}

#md-editor-buttons {
    z-index: 10;
    position: fixed;
    background: #fff;
    left: 0;
    right: 0;
    top: 0;
    padding: 10px 0 10px;
    margin: 0 10px;
}

.grid-contract {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
    overflow: hidden;
    padding: 2px;

    > div {
        width: 100%;
        // background: var(--background-color);
        padding: 10px;

        &.two {
            grid-column: span 2;
        }
        &.full {
            grid-column: span 4;
        }

        input, .search {
            width: 100%;
            min-width: 0;
            background: var(--background-color);
        }
    }
}

main {
    h1, h2, h3, h4, h5, h6 {
        color: var(--primary-color);
    }

    > * + *, section > * + * {
        margin-top: 10px;
    }

    section.leaf:hover {
        background-color: #f0f0f0;
    }
}

.md-editor {
    margin: 10px;

    em {
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        color: #bd9e39;
    }

    strong {
        font-style: normal;
        font-weight: bold;
        text-decoration: none;
        color: #6a9b94;
    }

    .underline {
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        color: #6a9b94;
    }

    .btn.md-b {
        font-weight: bolder;
        font-style: underline;
        background-color: #6a9b94;
        color: #000;

        opacity: 0.9;
        &:hover {
            opacity: 1;
        }
    }
    .btn.md-u {
        font-weight: normal;
        background-color: #6a9b94;
        color: #444;

        opacity: 0.9;
        &:hover {
            opacity: 1;
        }
    }

    .btn.md-i {
        font-weight: normal;
        background-color: #bd9e39;
        color: #444;

        opacity: 0.9;
        &:hover {
            opacity: 1;
        }
    }

    .btn.var, .btn.md-v {
        font-weight: normal;
        background-color: #f80;
        color: #444;

        opacity: 0.9;
        &:hover {
            opacity: 1;
        }
    }

    .btn.md-a {
        font-weight: normal;
        background-color: #444;
        color: #fff;

        opacity: 0.9;
        &:hover {
            opacity: 1;
        }
    }
}